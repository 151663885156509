import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Radio } from '@mui/material';

const theme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                 
                }
            }
        }
    },
    palette: {
        primaryRed: {
            main: '#FF4654',
            contrastText: '#1E1E1E',
        },
    },
});




const RadioWrapper = (props) => {

    return (
        <ThemeProvider theme={theme}>
            <Radio {...props} color='primaryRed'>
               {props.children}
            </Radio>
        </ThemeProvider>
    )
}

export default RadioWrapper;