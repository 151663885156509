import Iron from '../Ranks/iron.png'
import Bronze from '../Ranks/bronze.png'
import Silver from '../Ranks/silver.png'
import Gold from '../Ranks/gold.png'
import Platinum from '../Ranks/platinum.png'
import Diamond from '../Ranks/diamond.png'
import Radiant from '../Ranks/radiant.png'


const IconRanks = [{ "img": Iron, rank: "Iron" }, { "img": Bronze, rank: "Bronze" }, { "img": Silver, rank: "Silver" },
{ "img": Gold, rank: "Gold" }, { "img": Platinum, rank: "Platinum" }, { "img": Diamond, rank: "Diamond" },
{ "img": Radiant, rank: "Radiant" }];



export default IconRanks; 