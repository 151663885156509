import React from 'react'
import styles from './styles.module.css'
import topStyle from '../TopNavigation/styles.module.css'
import fstyles from '../DefaultStyles/flex.module.css'
import dstyles from '../DefaultStyles/styles.module.css'
import tstyles from '../DefaultStyles/text.module.css'
import TopBar from '../TopNavigation/TopBar'
import CustomButton from '../Buttons/CustomButton'
import Unrated from '../Resources/Ranks/questionMark.webp'
import "./maincontent.css"
import { STRATS} from '../Resources/Strats/strats.js'
import { useState, useRef } from 'react'
import RankIcon from './RankIcon/RankIcon'
import { CSSTransition } from 'react-transition-group';
import IconRanks from '../Resources/Constants/Icon';
import useMediaQuery from '@mui/material/useMediaQuery';

const Ranks = IconRanks;

const MainContent = () => {

    let [title, updateTitle] = useState("Welcome to Strat Roulette");
    let [strat, updateStrat] = useState("Click 'Next Strat' to get started!");
    let [rank, updateRank] = useState({ "img": Unrated, rank: "Unrated" });
    const [rankTransition, updateRankTransition] = useState(false);
    const nodeRef = useRef(null);

    function getStrat() {
        let rand = Math.floor(Math.random() * (STRATS.length - 1)) + 1;
        updateTitle(JSON.parse(JSON.stringify(STRATS[rand].title)));
        updateStrat(JSON.parse(JSON.stringify(STRATS[rand].message)));
        updateRank(Ranks[rand % 7]);
        updateRankTransition(true);
        setTimeout( ()=> {updateRankTransition(false)}, 50)
    }

    const max786 = useMediaQuery('(max-width:786px)');
    let buttonStyles = { padding: "18px 124px", fontSize: "xx-large" };
    if (max786){
        buttonStyles = {  };
    }

    return (
        <div className={[styles['width-90']].join(" ")}>
            <TopBar title={title} />

            <div className={[styles['main-small'], styles['background-light'],
            styles['margin-right-15'], styles['margin-bottom'], dstyles['scrollable'],
            styles['rounded-corners-main'], styles['box-shadow-main'], dstyles['height-100']].join(" ")}>

                <div className={[topStyle['grid-top-nav'], dstyles['height-100']].join(" ")}>
                    <div className={[fstyles['flex'], fstyles['dir-col'], fstyles['jc-flex-start'], dstyles['height-90']].join(" ")}>

                        <div className={[dstyles['text-center'], fstyles['flex'], fstyles['dir-col'], fstyles['jc-around'], dstyles['height-90']].join(" ")}>
                            <p className={[tstyles['strat-text'], styles['strat-text']].join(" ")}>{strat}</p>
                            <CSSTransition
                                in={rankTransition}
                                nodeRef={nodeRef}
                                timeout={200}
                                classNames="icon"
                            >
                                <RankIcon className={[styles['icon-mobile']].join(" ")} ref={nodeRef} src={rank.img} title={"Difficulty: " + rank.rank} />
                            </CSSTransition>
                            <div className={[fstyles['flex'], fstyles['jc-center']].join(" ")}>
                                <div className={[styles['margin-top-bot-auto']].join(" ")}>
                                    <CustomButton className={[styles['custom-button']].join(" ")} variant="contained" color="primaryRed" size="large" style={buttonStyles} onClick={getStrat}>Next Strat</CustomButton>
                                </div>

                            </div>
                        </div>
                        <p className={[dstyles["text-center"], tstyles['white-text']].join(" ")}>Valorant Strat Roulette was created under Riot Games' "Legal Jibber Jabber" policy using assets owned by Riot Games.  Riot Games does not endorse or sponsor this project.</p>
                    </div>
                </div>

            </div>
        </div>

    )

}


export default MainContent;