import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';

const theme = createTheme({
  components:{
    MuiButton:{
      styleOverrides:{
        root:{
            fontSize: "large",
            fontWeight: "bolder",
            textTransform: "none"
        }
      }
    }
  },
  palette: {
    primaryRed: {
      main: '#FF4654',
      contrastText: '#1E1E1E',
    },
  },
});


const CustomButton = (props) => {

    return(
        <ThemeProvider theme={theme}>
        <Button {...props}> {props.children}
        </Button>
      </ThemeProvider>
    )
}

export default CustomButton;