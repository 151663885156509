//FIX MR.PRESIDENT


const STRATS = [
    
    {title: "3rd Imposter", message:"Tell the enemy where one of your teammates is!"},
    {title: "Bravo 6, we're going dark...", message:"You must crouch and avoid line of sight this round!"},
    {title: "Tornado Incoming!", message: "You must do continual 360s for 15 seconds!"},
    {title: "Aphasia!", message: "You no longer can use correct callouts!"},
    {title: "Hoppy Easter!", message: "You must hop every 5 seconds!"},
    {title: "HACKED!!!", message: "Your sensitivity is cut in half and your crosshair must be changed to max settings!"},
    {title: "The Prodigy Baiter", message: "Bait your team by pushing on to site first and then walk away!"},
    {title: "Sticky Mouse!", message: "Your mouse has become sticky and you can only single fire now!"},
    {title: "Righty Clicky!", message: "You must purchase a gun (that has alt fire) and use its alt fire only!"},
    {title: "Sneaky Beaky!", message:"Try to sneak to the enemy’s spawn!"},
    {title: "It's Counter Strike Time!", message: "Win the round without using any abilities!"},
    {title: "WHOOSH!!!", message: "Your FOV is now at the lowest setting!"},
    {title: "Lone Bomber", message: "Go alone and sneakily plant the bomb!"},
    {title: "As the Russians say...", message: "RUSH B! DON’T STOP!"},
    {title: "Just like Tyler says...", message: "Rush all the way down mid!"},
    {title: "Jump shot!", message: "Try to kill someone while in mid air!"},
    //Shop strats
    {title: "There's a new Sheriff in town", message: "Buy a Sheriff this round!"},
    {title: "You just got robbed!", message: "You have no money and cannot buy this round!"},
    {title: "WHO LET THE DOGS OUT?", message: "Buy a Bulldog this round!"},
    {title: "Paranormal Activity in the Area", message: "Buy a Phantom or a Ghost this round!"}, 
    {title: "Toss a coin to your witcher", message: "Purchase a gun for your favorite player/agent!"},
    {title: "The Cake is a lie", message: "Ask a teammate to request a gun and then never buy it..."},
    {title: "Firesale", message: "Purchase the most expensive rifle and drop it for a teammate!"},
    {title: "Big Spender", message: "Bait the enemy with a pile of guns"},
    // Team Strats
    {title: "Choose your own adventure…", message: "Create your own wacky strat that you want to do!"},
    {title: "Trigger Finger!", message: "Shoot at every sound you hear!"},
    {title: "GLAWKS!", message: "You can only use a pistol this round!"},
    {title: "Dang, look at that Shawty!", message: "Everytime you need to reload, switch to a shorty instead!"},
    {title: "I SURRENDER!", message: "Try to get the enemies to take you hostage!"},
    {title: "Knife Fight!", message: "Try to get the enemies to knife fight in the center of the map!"},
    {title: "Never Bring a Knife to a Gunfight...", message: "Try to get the enemies to knife and betray their trust by shooting them!"},
    {title: "Every Agent for themselves!", message: "Get and stay as far away from your teammates as possible!"},
    {title: "Firing Squad", message: "Purchase an Odin or Aries and wait for the enemy to peak you!"},
    {title: "Story Time!", message: "Tell a fun story about how the game has been going!"},
    {title: "Manhunt!", message: "Choose a target and be the first to kill them"},
    {title: "FAKER!? WHAT WAS THAT!", message: "The first person to die must become an epic shout caster!"},
    {title: "Leap of Faith!", message: "Close your eyes and let one of your teammates guide you to the bombsite!"},
    {title: "Set up a tent!", message: "Camp in spawn for one minute!"},
    {title: "The Suns going down...", message: "Put on sunglasses for this round or squint!"},
    {title: "Your Princess is in another castle!", message:"Once you takeover a site, you must plant at a different site!"},
    {title: "Tag! You're it!", message: "Whomever is at the top of the scoreboard is \"it\" avoid them at all costs!"},
    {title: "Conga Line!", message: "In leaderboard order, create a conga line and head to a site!"},
    {title: "FUS, RO, DAH!!!", message: "Every time you kill an enemy, you must voice a mighty shout!"},
    {title: "The floor is lava!", message: "To the best of your ability, stay off the ground!"},
    {title: "Mr. President?", message: "The president is in the 3rd position. Protect them at all cost!"},
    {title: "Freeze! This is the Mannequin Challenge", message: "Every 15 seconds you have to stand completely still!"},
    {title:  "Paranoia!", message: "You have to play this round with your eyes squinted!"},
    {title: "Spray them down", message: "Everytime you shoot spray until you run out of bullets!"},
    {title: "Shhh. Keep quiet", message:"Play the entire round while whispering to your team"},
    {title: "Eye see you! 👁👄👁", message: "Keep one eye closed for the whole round!"}

]

const Titles = [
    // Solo Strats
    "3rd Imposter!","Bravo 6, we're going dark...", "Tornado Incoming!", "Aphasia!", "Hoppy Easter!",
    "HACKED!!!", "The Prodigy Baiter", "Sticky Mouse!", "Righty Clicky!", "Sneaky Beaky!", "It's Counter Strike Time!",
    "WHOOSH!!!", "Lone Bomber",
    "As the Russians say…","Just like Tyler says...", "Jump shot!",

    //Shop strats
    "There's a new Sheriff in town", "You just got robbed!", "WHO LET THE DOGS OUT?",
    "Paranormal Activity in the Area", "Toss a coin to your witcher", "The Cake is a lie",
    "Firesale","Big Spender",

    // Team Strats
    "Choose your own adventure…", "Trigger Finger!", "GLAWKS!",
    "Dang, look at that Shawty!", "I SURRENDER!", "Knife Fight!", "Never Bring a Knife to a Gunfight...",
    "Every Agent for themselves!", "Firing Squad", "Story Time!", "Manhunt!", "FAKER!? WHAT WAS THAT!",
    "Leap of Faith!", "Set up a tent!", " The Suns going down...", "Your Princess is in another castle!", "Tag! You're it!",
    "Conga Line!", "FUS, RO, DAH!!!", "The floor is lava!", "Mr. President?", "Freeze! This is the Mannequin Challenge",
    "Paranoia!","Spray them down", "Shhh. Keep quiet",

    // Newest Strats

    "Eye see you! 👁👄👁"

];

const Strats = [

    "Tell the enemy where one of your teammates is!", "You must crouch and avoid line of sight this round!",
    "You must do continual 360s for 15 seconds!", "You no longer can use correct callouts!", "You must hop every 5 seconds!",
    "Your sensitivity is cut in half and your crosshair must be changed to max settings!", "Bait your team by pushing on to site first and then walk away!",
    "Your mouse has become sticky and you can only single fire now!", "You must purchase a gun (that has alt fire) and use its alt fire only!",
    "Try to sneak to the enemy’s spawn!", "Win the round without using any abilities!", "Your FOV is now at the lowest setting!",
    "Whomever has the bomb has to plant and take the bombsite alone! (Teammates should go to a different site)",
    "RUSH B! DON’T STOP!","RUN IT DOWN MID!","Try to kill someone while in mid air!",

    "Buy a Sheriff this round!", "You have no money and cannot buy this round!", "Buy a Bulldog this round!",
    "Buy a Phantom or a Ghost this round!", "Purchase a gun for your favorite player/agent!", "Ask a teammate to request a gun and then never buy it...",
    "Purchase the most expensive rifle and drop it for a teammate!", "Bait the enemy with a pile of guns",

    

    "Create your own wacky strat that you want to do!", "Shoot at every sound you hear!", "You can only use a pistol this round!",
    "Everytime you need to reload, switch to a shorty instead!", "Try to get the enemies to take you hostage!", "Try to get the enemies to knife fight in the center of the map!",
    "Try to get the enemies to knife and betray their trust by shooting them!", "Get and stay as far away from your teammates as possible!",
    "Purchase an Odin or Aries and wait for the enemy to peak you!", "Tell a fun story about how the game has been going!", "Choose a target and be the first to kill them",
    "The first person to die must become an epic shout caster!", "Close your eyes and let one of your teammates guide you to the bombsite!",
    "Camp in spawn for one minute!", "Put on sunglasses for this round or squint!", "Once you takeover a site, you must plant at a different site!",
    "Whomever is at the top of the scoreboard is \"it\" avoid them at all costs!", "In leaderboard order, you must create a conga line!",
    "Every time you kill an enemy, you must voice a mighty shout!", "To the best of your ability, stay off the ground!",
    "The president is in the 3rd position. Protect them at all cost!", "Every 15 seconds you have to stand completely still!",
    "You have to play this round with your eyes squinted!", "Everytime you shoot, you must hold down the mouse, until you run out of bullets",
    "Play the entire round while whispering to your team",


    //Newest Strats

    "Keep one eye closed for the whole round!"
];



export {Titles, Strats, STRATS};