import Astra from '../AgentIcons/Astra.webp'
import Breach from '../AgentIcons/Breach.webp'
import Brimstone from '../AgentIcons/Brimstone.webp'
import Clove from '../AgentIcons/Clove.webp'
import Chamber from '../AgentIcons/Chamber.webp'
import Cypher from '../AgentIcons/Cypher.webp'
import Deadlock from '../AgentIcons/Deadlock.webp'
import Fade from '../AgentIcons/Fade.webp'
import Gekko from '../AgentIcons/Gekko.webp'
import Harbor from '../AgentIcons/Harbor.webp'
import Jett from '../AgentIcons/Jett.webp'
import KAYO from '../AgentIcons/KAYO.webp'
import KJ from '../AgentIcons/KJ.webp'
import Iso from '../AgentIcons/Iso.webp'
import Omen from '../AgentIcons/Omen.webp'
import Phoenix from '../AgentIcons/Phoenix.webp'
import Raze from '../AgentIcons/Raze.webp'
import Reyna from '../AgentIcons/Reyna.webp'
import Sage from '../AgentIcons/Sage.webp'
import Sova from '../AgentIcons/Sova.webp'
import Viper from '../AgentIcons/Viper.webp'
import Yoru from '../AgentIcons/Yoru.webp'

const AGENTS =
{
    Astra: { img: Astra, agent: "Astra" },
    Breach: { img: Breach, agent: "Breach" },
    Brimstone: { img: Brimstone, agent: "Brimstone" },
    Clove: {img: Clove, agent: "Clove"},
    Chamber: { img: Chamber, agent: "Chamber" },
    Cypher: { img: Cypher, agent: "Cypher" },
    Deadlock: {img: Deadlock, agent: "Deadlock"},
    Fade: { img: Fade, agent: "Fade" },
    Gekko: { img: Gekko, agent: "Gekko" },
    Habor: { img: Harbor, agent: "Harbor" },
    Iso: { img: Iso, agent: "Iso" },
    Jett: { img: Jett, agent: "Jett" },
    KayO: { img: KAYO, agent: "Kay/O" },
    Killjoy: { img: KJ, agent: "Killjoy" },
    Omen: { img: Omen, agent: "Omen" },
    Phoenix: { img: Phoenix, agent: "Phoenix" },
    Raze: { img: Raze, agent: "Raze" },
    Reyna: { img: Reyna, agent: "Reyna" },
    Sage: { img: Sage, agent: "Sage" },
    Sova: { img: Sova, agent: "Sova" },
    Viper: { img: Viper, agent: "Viper" },
    Yoru: { img: Yoru, agent: "Yoru" }
}


export default AGENTS;