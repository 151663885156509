import React from "react";
import { Avatar, Tooltip } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';



const RankIcon = React.forwardRef((props, ref) => {


    const max786 = useMediaQuery('(max-width:786px)');
    let width = 100;
    let height = 100;
    if (max786){
        width = 70;
        height = 70;
    }

    return (

        <Tooltip {...props} placement="bottom">
            <Avatar sx={{ width: width, height: height, marginLeft: "auto", marginRight: "auto" }} src={props.src} ref={ref}> </Avatar>
        </Tooltip>

    )
})


export default RankIcon; 