import React from "react";
import { CSSTransition } from 'react-transition-group' // ES6
import './transitions.css'
import { Tooltip } from '@mui/material';

// Change size based on the image
const Weapon = React.forwardRef((props, ref) => {

    let {height, width} = fixWeaponSize(props.title);

    return (
        <CSSTransition
            in={Boolean(props.visible)}
            ref={ref}
            timeout={200}
            classNames="weapon">
            <Tooltip {...props} placement="right">
                <img src={props.src} style={{ margin: "auto", height: height, width: width  }} alt={props.title}></img>
            </Tooltip>

        </CSSTransition >

    )

})

function fixWeaponSize(title) {
    const defaultHeight = "3.4em";
    const defaultWidth = "12.5em";
    let height = defaultHeight;
    let width = defaultWidth;
    switch (title) {

        case "Ares":
            height = "3.4em"
            width = "14.5em"
            break;

        case "Bucky":
            height = "2.4em";
            width = "12.5em";
            break;

        case "Bulldog":
            height = "3.4em";
            width = "10.5em";
            break;
        
        case "Classic":
            width = "5.5em";
            break;
            
        case "Ghost":
            width = "10em";
            break;

        case "Frenzy":
            width = "5em";
            break;

        case "Guardian":
            height = "3.1em";
            break;

        case "Knife":
            height = "2.5em";
            width = "8.5em";
            break;

        case "Marshal":
            height = "2.5em";
            break;

        case "Operator":
            height = "3em";
            width = "12em";
            break;

        case "Sheriff":
            width = "8.5em";
            break;
        case "Stinger":
            height = "4.5em";
            break;

        case "Spectre":
            height = "4.4em";
            break;

        default:
            height = defaultHeight;
            width = defaultWidth;
    }

    return({height, width})
}




export default Weapon;