import './App.css';
import './TopNavigation/TopBar'
import LeftSide from './LeftSide/LeftSide';
import MainContent from './MainContent/MainContent';
import FrontPage from './FrontPage/FrontPage';
import { useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import background from './Resources/Backgrounds/background1.jpg';
import mobileBackground from './Resources/Backgrounds/background2.jpg'
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

function App() {

  /* Firebase stuff */
  // Import the functions you need from the SDKs you need

  // TODO: Add SDKs for Firebase products that you want to use
  // https://firebase.google.com/docs/web/setup#available-libraries

  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = {
    apiKey: "AIzaSyAoqzjsap-8FzMFrCAulBRkRZ6-gtyWJiU",
    authDomain: "strat-roulette-prod.firebaseapp.com",
    projectId: "strat-roulette-prod",
    storageBucket: "strat-roulette-prod.appspot.com",
    messagingSenderId: "679542994675",
    appId: "1:679542994675:web:cece81ed56c600fe1ea986",
    measurementId: "G-GMB42YTD82"
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  /* End of  Firebase stuff */
  
  return (
    <>
      <div className='flex height-100'>
        <LeftSide /> 
        <MainContent />
      </div>
    </>
  );
}

export default App;
