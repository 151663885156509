import { useEffect, useState, useRef } from 'react';
import styles from './styles.module.css'
import fstyles from '../DefaultStyles/flex.module.css'
import dstyles from '../DefaultStyles/styles.module.css'
import CustomButton from '../Buttons/CustomButton';
import AgentIcon from './AgentIcon/AgentIcon';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Weapon from './WeaponWrapper/Weapon';
import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide} from '@mui/material';
import questionMark from '../Resources/Ranks/questionMark.webp'
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';


import { CSSTransition } from 'react-transition-group';
import './transitions.css'

import React from 'react';
import RadioWrapper from './RadioWrapper/RadioWrapper';

import AGENTS from '../Resources/Constants/Agents';
import GUNS from '../Resources/Constants/Guns';

const agentArray = [
    AGENTS.Astra, AGENTS.Breach, AGENTS.Brimstone, AGENTS.Clove, AGENTS.Chamber, AGENTS.Cypher, AGENTS.Deadlock,
    AGENTS.Fade, AGENTS.Gekko, AGENTS.Habor, AGENTS.Iso,AGENTS.Jett, AGENTS.KayO, AGENTS.Killjoy, AGENTS.Omen, 
    AGENTS.Phoenix, AGENTS.Raze, AGENTS.Reyna, AGENTS.Sage, AGENTS.Sova, AGENTS.Viper, AGENTS.Yoru
];

const gunArray = [
    GUNS.Ares, GUNS.Bucky, GUNS.Bulldog, GUNS.Guardian,
    GUNS.Judge, GUNS.Marshal, GUNS.Spectre, GUNS.Odin, GUNS.Operator, GUNS.Phantom,
    GUNS.Spectre, GUNS.Stinger, GUNS.Knife
];

const trashPistols = [GUNS.Stinger, GUNS.Ghost, GUNS.Ares, GUNS.Judge, GUNS.Knife, GUNS.Classic, GUNS.Frenzy, GUNS.Sheriff, GUNS.Shorty];

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const darkBlue = "#0f1922";
const tomatoRed = "#ff4654";

const LeftSide = () => {
    let [agents, updateAgentArray] = useState([...agentArray]);
    let [enabled, updateEnabled] = useState(true);
    let [guns, updateGuns] = useState([...gunArray]);
    let [oldGuns, updateOldGuns] = useState([...gunArray]);
    let [radioValue, updateRadioValue] = useState("agents");
    let [gunCheck, updateGunCheck] = useState(true);
    let [agentCheck, updateAgentCheck] = useState(true);
    let [firstTimeGun, updateFirstTimeGun] = useState(true);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    let agentTransition = agentCheck && enabled;
    if (agentCheck == false) {
        agentTransition = true;
    }
    let gunTransition = gunCheck && enabled;
    if (gunCheck == false) {
        gunTransition = true;
    }


    function randomizeAgents() {

        if (enabled) {

            if (radioValue == 'agents') {
                let randomAgents = JSON.parse(JSON.stringify(agents))
                    .map(value => ({ value, sort: Math.random() }))
                    .sort((a, b) => a.sort - b.sort)
                    .map(({ value }) => value);

                setTimeout(() => { updateAgentArray(JSON.parse(JSON.stringify(randomAgents))); }, 500)
                updateAgentCheck(true);
                updateGunCheck(false);
            }

            if (radioValue == 'guns') {

                let randomGuns = randomizeArray(gunArray);
                let random = Math.random();
                let tempRandom = [];

                if (firstTimeGun){
                    tempRandom = newRandom(random, randomGuns);
                    updateOldGuns(newRandom(random * random, randomizeArray(gunArray)));
                    if(compareArrays(tempRandom.slice(0, 5), oldGuns.slice(0,5))){
                        updateOldGuns(newRandom(random * random, randomizeArray(gunArray)));
                    }
                    updateFirstTimeGun(false);
                }
                else{
                    tempRandom =  JSON.parse(JSON.stringify(oldGuns));
                    updateOldGuns(newRandom(random * random, randomizeArray(gunArray)));
                }
             
                updateAgentCheck(false);
                updateGunCheck(true);

                updateGuns(JSON.parse(JSON.stringify(tempRandom)));
            }



            updateEnabled(false);
        }

        setTimeout(() => { updateEnabled(true); }, 500);
    }

    function newRandom(random, randomGuns) {
        let tempRandom =  JSON.parse(JSON.stringify(randomGuns));
        let trashGuns = JSON.parse(JSON.stringify(trashPistols)).map(value => ({ value, sort: Math.random() }))
            .sort((a, b) => a.sort - b.sort)
            .map(({ value }) => value);
        tempRandom.fill(randomGuns[0]);
        
        if (random < .04) {
            tempRandom[0] = JSON.parse(JSON.stringify(trashGuns[0]));
        }
        else if (random < .08 && random > .04) {
            tempRandom[1] = JSON.parse(JSON.stringify(trashGuns[0]));
        }
        else if (random < .12 && random > .08) {
            tempRandom[2] = JSON.parse(JSON.stringify(trashGuns[0]));
        }
        else if (random < .16 && random > .12) {
            tempRandom[3] = JSON.parse(JSON.stringify(trashGuns[0]));
        }
        else if (random < .20 && random > .16) {
            tempRandom[4] = JSON.parse(JSON.stringify(trashGuns[0]));
        }

        return JSON.parse(JSON.stringify(tempRandom));
    }

    function randomizeArray(array){
        let randomGuns =  JSON.parse(JSON.stringify(array))
        .map(value => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value);

        return randomGuns;
    }

    function compareArrays(array1, array2){
        return JSON.stringify(array1) == JSON.stringify(array2)
    }


    const agentRef = useRef(null);
    const gunRef = useRef(null);
    useEffect(() => {
        randomizeAgents();
    }, []);

    const radioHandleChange = (event) => {
        updateRadioValue(event.target.value);
    }


    return (
        <div className={[fstyles['flex'], fstyles['jc-center'], fstyles['dir-col'], styles['left-bar'], styles['margin-top'], dstyles["mobile-hide"]].join(" ")}>
            <CustomButton variant="contained" color="primaryRed" onClick={randomizeAgents}>Randomize</CustomButton>
            <FormControl >
                <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    className={[styles['margin-left-right-auto']].join(" ")}
                    value={radioValue}
                    onChange={radioHandleChange}
                >
                    <FormControlLabel value="agents" control={<RadioWrapper />} label="Agents" />
                    <FormControlLabel value="guns" control={<RadioWrapper />} label="Weapons" />
                </RadioGroup>
            </FormControl>


            <Stack className={[styles['margin-top-half']]} direction="row">

                <CSSTransition
                    in={agentTransition}
                    nodeRef={agentRef}
                    timeout={3000}
                    classNames="agents"
                >
                    <Stack direction="column" spacing={3} marginLeft="auto" marginRight="auto" ref={agentRef}>

                            <AgentIcon className={[styles['fade']].join(" ")} src={agents[0].img} title={agents[0].agent}/>
                            <AgentIcon className={[styles['fade']].join(" ")} src={agents[1].img} title={agents[1].agent}/>
                            <AgentIcon className={[styles['fade']].join(" ")} src={agents[2].img} title={agents[2].agent}/>
                            <AgentIcon className={[styles['fade']].join(" ")} src={agents[3].img} title={agents[3].agent}/>
                            <AgentIcon className={[styles['fade']].join(" ")} src={agents[4].img} title={agents[4].agent}/>

                    </Stack>
                </CSSTransition>

                <CSSTransition
                    in={gunTransition}
                    nodeRef={gunRef}
                    timeout={200}
                    classNames="guns"
                >
                    <Stack direction="column" spacing={3} marginLeft="auto" marginRight="auto" ref={gunRef}>
                        <Weapon className={[styles['fade']].join(" ")} src={guns[0].img} title={guns[0].gun}></Weapon>
                        <Weapon className={[styles['fade']].join(" ")} src={guns[1].img} title={guns[1].gun}></Weapon>
                        <Weapon className={[styles['fade']].join(" ")} src={guns[2].img} title={guns[2].gun}></Weapon>
                        <Weapon className={ [styles['fade']].join(" ")} src={guns[3].img} title={guns[3].gun}></Weapon>
                        <Weapon className={[styles['fade']].join(" ")} src={guns[4].img} title={guns[4].gun} padding={'.01em'}></Weapon>
                    </Stack>
                </CSSTransition>
            </Stack>

            <Avatar className={[styles['question']].join(" ")} sx={{ width: 75, height: 75 }} style={{ marginLeft: "auto", marginRight: "auto" }} src={questionMark}
                onClick={handleOpen}> </Avatar>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                style={{ background: "transparent"}}
            >
                <DialogTitle style={{background:darkBlue, color:tomatoRed, fontWeight:'bold'}}>{"Thanks for playing!"}</DialogTitle>
                <DialogContent style={{background:darkBlue}}>
                    <DialogContentText  id="alert-dialog-slide-description" style={{color:tomatoRed, fontWeight:'bold'}}>
                        Hope you enjoyed playing Strat Roulette! 
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{background:darkBlue, color:tomatoRed}}>
                    <Button onClick={handleClose} style={{color:tomatoRed, fontWeight:'bold'}}>Close</Button>
                    {/* <Button onClick={handleClose} style={{color:tomatoRed, fontWeight:'bold'}}>Support</Button> */}
                </DialogActions>
            </Dialog>
        </div >
    );
}


export default LeftSide;