import styles from "./styles.module.css";
import fstyles from '../DefaultStyles/flex.module.css'
import dstyles from '../DefaultStyles/styles.module.css'
import tstyles from '../DefaultStyles/text.module.css'


const TopBar = (props) => {
    return (
        <div className={[styles['top-nav'], styles['margin-2'], styles['grid-top-nav'], dstyles['main-background'],].join(" ")}>
            <span className={[dstyles['text-center'], fstyles['grid-row-1'], tstyles['strat-text']].join(" ")}>{props.title}
            </span>
        </div>
    )
}
export default TopBar;