
import { Tooltip } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';

import './AgentIcon.css'


const AgentIcon = React.forwardRef((props, ref) => {

    const min600 = useMediaQuery('(min-width:600px)');
    const min992 = useMediaQuery('(min-width:992px)');
    const min1200 = useMediaQuery('(min-width:1200px)');
    const min1400 = useMediaQuery('(min-width:1400px)');
    const min1600 = useMediaQuery('(min-width:1600px)');

    if (min1600) {

        return (
            <Tooltip {...props} placement="bottom">
                <Avatar src={props.src} ref={ref}
                    style={{ marginLeft: "auto", marginRight: "auto", background: "#060d18", border: "3px solid #0F1922", width: 80, height: 80 }}>
                </Avatar>
            </Tooltip>
        )
    }

    else if (min1400) {
        return (
            <Tooltip {...props} placement="bottom">
                <Avatar src={props.src} ref={ref}
                    style={{ marginLeft: "auto", marginRight: "auto", background: "#060d18", border: "3px solid #0F1922", width: 60, height: 60 }}>
                </Avatar>
            </Tooltip>
        )
    }

    else if (min1200) {
        return (
            <Tooltip {...props} placement="bottom">
                <Avatar src={props.src} ref={ref}
                    style={{ marginLeft: "auto", marginRight: "auto", background: "#060d18", border: "3px solid #0F1922", width: 50, height: 60 }}>
                </Avatar>
            </Tooltip>
        )
    }

    else if (min992) {
        return (
            <Tooltip {...props} placement="bottom">
                <Avatar src={props.src} ref={ref}
                    style={{ marginLeft: "auto", marginRight: "auto", background: "#060d18", border: "3px solid #0F1922", width: 40, height: 40 }}>
                </Avatar>
            </Tooltip>
        )
    }

    else if (min600) {
        return (
            <Tooltip {...props} placement="bottom">
                <Avatar src={props.src} ref={ref}
                    style={{ marginLeft: "auto", marginRight: "auto", background: "#060d18", border: "3px solid #0F1922", width: 35, height: 35 }}>
                </Avatar>
            </Tooltip>
        )
    }


    return (
        <Tooltip {...props} placement="bottom">
            <Avatar src={props.src} ref={ref}
                style={{ marginLeft: "auto", marginRight: "auto", background: "#060d18", border: "3px solid #0F1922", width: 90, height: 90 }}>
            </Avatar>
        </Tooltip>
    )

})


export default AgentIcon;